const Endpoints = {
  // Account
  Login: 'Account/login',
  Register: 'Account/register',
  UserSetStatus: 'Account/user/setLockoutStatus',
  ResetPassword: 'Account/user/resetPassword',
  GetUsers: 'Account/users',
  GetFirmaUsers: 'Account/firma/users',
  GetFirmaIdByUserId: 'Account/getFirmaIdByUserId',
  UpdateUserPassword: 'Account/user/changePassword',

  // Menu
  MenuList: 'Menu/liste',

  // Yetki Rol
  GetRole: 'Common/rol',
  GetUser: 'Common/user',

  // Dashboards Endpoints
  Dashboard: 'Dashboard/dashboard',

  // Common Endpoints
  FileUpload: 'Common/dosya/yukle',
  GetTaskTracking: 'Common/isTakip/liste',
  GetTaskTrackingMove: 'Common/isTakip/hareket',
  PostTaskTracking: 'Common/isTakip',
  PostTaskTrackingMove: 'Common/isTakip/hareket',
  UpdateTaskTracking: 'Common/isTakip',
  GetPublicHolidays: 'Common/resmiGunler',
  AddOrUpdatePublicHolidays: 'Common/resmiGunler',
  DeletePublicHolidays: 'Common/resmiGunler',
  SayacGetir: 'Common/sayac',
  GetJobTrackingListByUser: 'Common/getJobTrackingList',

  // Report File Upload
  GatesFileUpload: 'OzelRapor/gates/dosya/yukle',
  GatesFileDownload: 'OzelRapor/gates/dosya/indir',

  // Acente Gemi Endpoints
  GetShip: 'Acente/gemi/liste',
  CreateShip: 'Acente/gemi/ekle',
  UpdateShip: 'Acente/gemi/guncelle',
  DeleteShip: 'Acente/gemi/sil',

  // Acente Konteyner Endpoints
  GetContainer: 'Acente/konteyner/liste',
  CreateContainer: 'Acente/konteyner/ekle',
  UpdateContainer: 'Acente/konteyner/guncelle',
  DeleteContainer: 'Acente/konteyner/sil',
  PatchSoContainer: 'Acente/konteyner/so/guncelle',

  // Acente
  GetExportSettings: '/Acente/ihracat/ayarlar',
  GetStock: '/Acente/stok',
  CreateStock: '/Acente/stok',
  GetCustomer: '/Acente/musteri',
  CreateCustomer: '/Acente/musteri',
  GetPort: '/Acente/liman',
  GetCustoms: '/Acente/gumruk',
  GetDuzeltmeTuru: '/Acente/talep/duzeltmeTurleri',
  CreateAcenteDemand: '/Acente/talep',
  UpdateAcenteDemand: '/Acente/talep/guncelle',
  GetAcenteDemand: '/Acente/talepler',
  GetOneAcenteDemand: '/Acente/talepler',
  AcenteFileUpload: 'Acente/dosya/yukle',
  AcenteFileDocToPdf: 'Acente/dosya/docToPdf',
  GetConfirmation: 'Acente/talep/onay',
  PutDemandConfirmation: 'Acente/talep',
  PostDemandResult: 'Acente/talep/sonuc',
  GetDemandResult: 'Acente/talep/sonuc',
  GetContainerByBookingNo: 'Acente/talep/konteynerByBookingNo',
  GetLines: '/Acente/line',
  GetEvacuation: '/Acente/tahliye',
  GetLoading: '/Acente/yukleme',
  GetTariff: '/Acente/tarife',

  // Acente Gemi Hareket EndPoints
  GetVesselMove: '/Acente/gemi/hareket',
  CreateVesselMove: '/Acente/gemi/hareket',
  DeleteVesselMove: '/Acente/gemi/hareket',
  UpdateVesselMove: '/Acente/gemi/hareket/guncelle',
  GetKtfInfo: '/Acente/ktf/info',

  // Acente Masraf EndPoints
  CreateCost: '/Acente/masraf',
  GetCostByTypeId: '/Acente/masraf',
  CreateStampDuty: '/Acente/damga/vergisi',

  // Acente Po Endpoints
  GetPoFile: '/Acente/po/dosya',
  CreatePoList: '/Acente/po/yukle',
  CheckPOList: '/Acente/po/kontrol',
  GetPoList: '/Acente/po/liste',
  GetPoListDetail: '/Acente/po/liste',
  PatchApprovalPo: '/Acente/po/onay',
  PatchApprovalCancelPo: '/Acente/po/onay/kaldir',
  PatchEvrimPO: '/Acente/po/evrim',
  PatchPoNo: '/Acente/po/poNo',
  PatchApprovalPartialPo: '/Acente/po/parcali/onay',
  PatchCancelPartialPo: '/Acente/po/parcali/kaldir',

  // Ihracat EndPoints
  UploadZip: 'Ihracat/dosya/yukle',
  DownloadZip: 'Ihracat/dosya/indir',
  DownloadExcel: 'Ihracat/dosya/excel/indir',
  DownloadBeyannameOlmayan: 'Ihracat/dosya/beyannamesiOlmayanlar',
  DownloadInconsistency: 'Ihracat/dosya/tutarsizlik/indir',
  BookingComplete: '/Ihracat/booking/ekle',
  BookingCheck: '/Ihracat/booking/kontrol',
  BookingFileCheck: '/Ihracat/booking/dosya/kontrol',
  BookingList: '/Ihracat/booking/liste',
  BookingUpdateOrCreate: '/Ihracat/booking/liste/guncelle',
  BookingTransfer: '/Ihracat/booking/liste/transfer',
  BookingDelete: '/Ihracat/booking/liste/sil',
  BookingBeyannameKontrol: '/Ihracat/booking/beyannameno/kontrol',
  BookingBeyanname: '/Ihracat/booking/beyanname',
  BookingInfo: '/Ihracat/booking/beyannameno/info',

  // Ithalat EndPoints
  UploadFileImport: 'Ithalat/dosya/yukle',
  ExitScreenUploadFileImport: 'Ithalat/cikis/dosya/yukle',
  UploadRoadFileImport: 'Ithalat/karayolu/dosya/yukle',
  MergeFile: 'Ithalat/mergeFile',
  AddImport: 'Ithalat/ekle',
  DeleteImport: 'Ithalat/sil',
  GetImport: 'Ithalat/liste',
  GetExitImport: 'Ithalat/cikis/liste',
  AddNotMatchImport: 'Ithalat/cikis/eslesmeyen',
  DownloadImport: 'Ithalat/tescil/dosya/indir',
  DownloadRoadImport: 'Ithalat/karayolu/tescil/dosya/indir',
  RegistryImport: 'Ithalat/tescil',
  RegistryExitImport: 'Ithalat/tescil/cikis',
  DeleteRegistryImport: 'Ithalat/tescil/sil',
  GetContainerMoveList: 'Ithalat/konteyner/hareket/liste',
  PatchOzetBeyan: 'Ithalat/ozetBeyan',
  GetRoadImport: 'Ithalat/karayolu/giris',
  AddRoadImport: 'Ithalat/karayolu/ekle',
  PatchContainerComment: 'Ithalat/konteyner/aciklama',
  PatchContainerTime: 'Ithalat/konteyner/sure',
  GetRulesImport: 'Ithalat/cikis/kurallar',
  GetTurlimList: 'Ithalat/turlim',
  PatchLine: 'Ithalat/line',
  GetImportContainer: 'Ithalat/konteyner',

  // Serbest Bölge EndPoints
  CreateInvoice: 'SB/fatura',
  PatchInvoice: 'SB/fatura',
  UpdateInvoice: 'SB/fatura',
  GetInvoices: 'SB/fatura',
  DeleteInvoice: 'SB/fatura',
  DownloadWorkOrder: 'SB/fatura/isEmri',
  DownloadWorkOrderByPDF: 'SB/fatura/isEmri/pdf',
  DownloadDraftWinSBBUPFile: 'SB/fatura/winSBBUP/taslak',
  UpdateWinSBBUPInvoice: 'SB/fatura/winSBBUP',
  CreateFileEvrim: 'SB/fatura/evrim/dosya',
  GetShipping: 'SB/nakliyeci',
  CreateShipping: 'SB/nakliyeci',
  GetSBStock: 'SB/stok',
  CreateSBStock: 'SB/stok',
  CreateRefByInvoice: 'SB/fatura/referans',
  UpdateRefByInvoice: 'SB/fatura/referans/guncelle',
  CreateManuelInvoice: 'SB/fatura/manuel',
  UpdateInvoiceItem: 'SB/fatura/kalem',
  GetInvoiceItem: 'SB/fatura/kalem',
  CheckInvoices: 'SB/fatura/kontrol',
  PatchInvoiceZone: 'SB/fatura/bolge',
  DownloadUBFDraft: 'SB/ubf/taslak',
  CheckEvrimRefInvoice: 'SB/fatura/evrimRef',

  // Antrepo Stok EndPoints
  UploadGitFile: 'AntrepoStok/git/dosya/yukle',
  UploadAnotherFile: 'AntrepoStok/git/evrak/yukle',
  AddGit: 'AntrepoStok/git',
  getAllData: 'AntrepoStok/git/data',
  getOneDocument: 'AntrepoStok/git/data',
  downloadGit: 'AntrepoStok/git/indir',
  GetInvoicesData: 'AntrepoStok/git/fatura',
  AddConsignmentNote: 'AntrepoStok/git/fatura/konsimento/ekle',
  UpdateConsignmentNote: 'AntrepoStok/git/fatura/konsimento/guncelle',
  AddPaymentDeclaration: 'AntrepoStok/git/odemeBildirimi/ekle',
  UpdatePaymentDeclaration: 'AntrepoStok/git/odemeBildirimi/guncelle',
  UpdateEta: 'AntrepoStok/git/fatura/eta',
  GetSoList: 'AntrepoStok/git/solist',
  AddSoList: 'AntrepoStok/git/solist',
  PatchSoList: 'AntrepoStok/git/solist/onay',
  GetSoProductList: 'AntrepoStok/git/so/urunler',
  GetOneInvoicesData: 'AntrepoStok/git/fatura/detaylar',
  GetCurrency: 'AntrepoStok/git/fatura/selectBoxDoviz',
  GetDelivery: 'AntrepoStok/urun/selectBoxTeslim',
  GetTransport: 'AntrepoStok/urun/selectBoxModeOfTransport',
  GetPlant: 'AntrepoStok/urun/selectBoxPlant',
  GetProductCategory: 'AntrepoStok/urun/selectBoxCategory',
  GetProductSubCategory: 'AntrepoStok/urun/selectBoxSubCategory',
  getMeasurementUnit: 'AntrepoStok/urun/selectBoxMeasurementUnit',
  getOrigin: 'AntrepoStok/urun/selectBoxOrigin',
  GetWarehouseStock: 'AntrepoStok/antrepoStok',
  CreateDemand: 'AntrepoStok/talep',
  GetDemand: 'AntrepoStok/talep/listesi',
  GetDemandInfo: 'AntrepoStok/talep/info',
  UploadDemand: 'AntrepoStok/talep/yukle',
  DeleteDemand: 'AntrepoStok/talep/sil',
  CreateDemandByExcel: 'AntrepoStok/talep/excel',
  UpdateDemandEvrimNo: 'AntrepoStok/talep/update/evrimNo',
  addOrUpdateInvoice: 'AntrepoStok/git/fatura',
  UpdateInvoicesDetail: 'AntrepoStok/git/fatura/kalem',
  getProductList: 'AntrepoStok/git/urun/liste',
  getProductAll: 'AntrepoStok/git/urun/liste/all',
  getWantedProducts: 'AntrepoStok/git/urun/',
  GetOneProductWithProductId: 'AntrepoStok/git/urun/detay',
  GetGroupDetailInProductDetail: 'AntrepoStok/grup/detay',
  addOrUpdateProduct: 'AntrepoStok/git/urun/ekle',
  GetProductListWithGGBS: 'AntrepoStok/git/urun/bildirim',
  //addOrUpdateANUrunBildirim: 'AntrepoStok/git/urun/bildirim/ekle',
  GetOneProductDeclaration: 'AntrepoStok/git/urun/bildirim',
  GetWantedProductsDeclarations: 'AntrepoStok/git/urun/bildirimler',
  DownloadDocument: 'AntrepoStok/git/evrak',
  DownloadTpsExcel: 'AntrepoStok/tps/excel',
  UpdateProductDeclaration: 'AntrepoStok/git/urun/bildirim/guncelle',
  GetDocumentModalFirstMountData: 'AntrepoStok/git/dosya/evraklar',
  getOneProductConditionDetail: 'AntrepoStok/git/kriter',
  getProductDocumentControlInfo: 'AntrepoStok/git/urun/evrakkontrol',
  getOneProductDocumentUploadedStatus: 'AntrepoStok/git/urun/evrakkontrol',
  getOneProductUploadedDocumentCheck: 'AntrepoStok/git/urun/yukludosya/',
  GetWantedBeyanname: 'AntrepoStok/git/beyanname',
  GetWantedWorkChase: 'AntrepoStok/git/beyanname/dosya',
  AddDocumentOneInstance: 'AntrepoStok/git/evrak/iliskilendirme',
  GetOneInvoiceUploadedDocument: 'AntrepoStok/git/fatura/yukludosya',
  GetAllShipmentDeclarations: 'AntrepoStok/git/sevkiyatbildirimleri',
  GetOneShipmentDeclarationForProductId:
    'AntrepoStok/git/sevkiyatbildirimi/urun',
  GetOneShipmentDeclarationDocumentStatus:
    'AntrepoStok/git/sevkiyatbildirimi/evrak',
  UpdateOneShipmentDeclaration: 'AntrepoStok/git/sevkiyatbildirimi/guncelle',
  GetOneShipmentDeclarationWithProductId:
    'AntrepoStok/git/sevkiyatbildirimi/detay',
  GetOneShipmentDeclarationAllDetailWithProductId:
    'AntrepoStok/git/sevkiyatbildirimi',
  GetReportAntrepoStok: 'AntrepoStok/git/antrepoStokRapor',
  GetWantedImportDeclarations: 'AntrepoStok/git/ithalatBeyannameleri',
  GetAllDocumentInSystem: 'AntrepoStok/git/tumEvraklarListe',
  GetAllRealAntrepos: 'AntrepoStok/git/antrepoListesi',
  GetWantedAntrepo: 'AntrepoStok/git/antrepo',
  DeleteDocumentInDocumentDetail: 'AntrepoStok/git/fatura/sil',
  GetGitWantedInvoices: 'AntrepoStok/git/fatura',
  GetInvoiceWorkChase: 'AntrepoStok/git/fatura/tescilDurumu/',
  GetPaymentDeclaration: 'AntrepoStok/git/odemeBildirimi',
  GetBeyannameDetay: 'AntrepoStok/git/beyanname/detay',
  GetIhracatBeyannameDetay: 'AntrepoStok/git/ihracat/beyanname/detay',
  DeleteDocument: 'AntrepoStok/git/evrakSil',
  PatchSoNo: 'AntrepoStok/git/update/soNo',
  GetCustomsData: 'AntrepoStok/git/gumrukler',
  GetCountries: 'AntrepoStok/git/ulkeler',
  GetBanks: 'AntrepoStok/git/bankalar',
  GetWGBeyanname: 'AntrepoStok/git/beyannameSorgulama',
  GetEvrimArchive: 'AntrepoStok/evrim/arsiv',
  GetEvrimArchiveDocument: 'AntrepoStok/evrim/arsiv/indir/',
  GetGenelRaporlar: 'AntrepoStok/genelRaporlar',
  GetWantedGenelRaporDetay: 'AntrepoStok/genelRaporDetayi',
  GetCompanyData: 'AntrepoStok/firmaBilgileri',
  DownloadExpectedExcelType: 'AntrepoStok/excel/formatGetir',
  GetProductUnitPrice: 'AntrepoStok/urunBirimFiyat',
  GetTalepListesi: 'AntrepoStok/talepListesi',
  DeleteTalep: 'AntrepoStok/talepDusum',
  PernordAntrepoBeyanOlustur: 'AntrepoStok/pernordAntrepoBeyanOlustur',
  GetGtipListesi: 'AntrepoStok/gtip',
  AddGtip: 'AntrepoStok/gtipEkle',
  GetGtipDetay: 'AntrepoStok/gtip/',
  AddGtipDetay: 'AntrepoStok/gtipDetay',
  TalepBeyannameOnızleme: 'AntrepoStok/talepBeyannameOnizleme/',
  OldSoList: 'AntrepoStok/oldSoList',
  PutOldSo: 'AntrepoStok/oldSo',
  GetDemandDocumentInfo: 'AntrepoStok/demand/missingInfo/',
  GetGtipDocumentType: 'AntrepoStok/getGtipDocumentType/',
  addOrUpdateGtipDocumentType: 'AntrepoStok/addOrUpdateGtipDocumentType',
  GetProductDocumentControl: 'AntrepoStok/getProductDocumentControl/',
  OrderRequiredDocuments: 'AntrepoStok/orderRequiredDocuments/',
  addOrUpdateProductDocumentControl:
    'AntrepoStok/addOrUpdateProductDocumentControl',
  AddDocumentTracking: 'AntrepoStok/belgeTakip/ekle',
  DeleteDocumentTracking: 'AntrepoStok/BelgeTakip/sil',
  GetDocumentTracking: 'AntrepoStok/belgeTakip/liste',
  UpdateDocumentTracking: 'AntrepoStok/belgeTakip/update',
  GetDocumentDetail: 'AntrepoStok/belgeTakip/detay/',
  AddDocumentProduct: 'AntrepoStok/belgeTakip/urun/ekle',
  UpdateDocumentProduct: 'AntrepoStok/belgeTakip/urun/update',
  GetDocumentProduct: 'AntrepoStok/belgeTakip/urun/liste/',
  AddOrder: 'AntrepoStok/order/add',
  UpdateOrder: 'AntrepoStok/order/update',
  UpdateRequiredOrder: 'AntrepoStok/order/required/update',
  ListOrder: 'AntrepoStok/order/list/',
  getWarehouseDefinitions: 'AntrepoStok/antrepoTanimlari',
  getAgentDefinitions: 'AntrepoStok/acenteTanimlari',
  addAgentDefinitions: 'AntrepoStok/acenteEkle',
  getBankDefinitions: 'AntrepoStok/bankaTanimlari',
  AddOrderNotes: 'AntrepoStok/order/notes/add',
  ListOrderNotes: 'AntrepoStok/order/notes/list/',
  AddOrderDeclaration: 'AntrepoStok/order/declaration/add',
  ListOrderDeclaration: 'AntrepoStok/order/declaration/list/',
  ListDocumentRezerved: 'AntrepoStok/belgeTakip/rezerve/',
  DocumentTrackingSearch: 'AntrepoStok/belgeTakip/belge/search/',
  DocumentRezerveSave: 'AntrepoStok/belgeTakip/rezerveKaydet/',
  DocumentRezerveDelete: 'AntrepoStok/belgeTakip/rezerveSil',
  AddOrderExcel: 'AntrepoStok/order/dosya/yukle',
  AddPirExcel: 'AntrepoStok/pir/dosya/yukle',
  AddPRExcel: 'AntrepoStok/pr/dosya/yukle',
  AddMasrafExcel: 'AntrepoStok/order/masrafExcel/yukle',
  OrderLogsList: 'AntrepoStok/order/logs/',
  GeciciIthalatListe: 'AntrepoStok/geciciIthalat/liste/',
  GeciciIthalatUpdate: 'AntrepoStok/geciciIthalat/update',
  GetOriginalDocumentTrackingList:
    'AntrepoStok/getOriginalDocumentTrackingList',
  GetOriginalDocumentTrackingInvoiceNo:
    'AntrepoStok/getOriginalDocumentTrackingByInvoiceNo/',
  AddOriginalDocumentTracking: 'AntrepoStok/addOriginalDocumentTracking',
  AddOriginalDocumentTrackingList:
    'AntrepoStok/addOriginalDocumentTrackingList',
  UpdateOriginalDocumentTracking: 'AntrepoStok/updateOriginalDocumentTracking',
  GetOriginalDocumentTypeList: 'AntrepoStok/getOriginalDocumentTypeList',
  ListProductMasterData: 'AntrepoStok/product/masterData/',
  UpdateProductMasterData: 'AntrepoStok/product/masterData/update',
  AddProductMasterData: 'AntrepoStok/product/masterData/add',
  sunChemicalSenk: 'AntrepoStok/sunChemical/senkronizasyon',
  ConvertOrdersToExcelReport: 'AntrepoStok/convertOrdersToExcelReport/',
  ConvertOrdersToExcelReportID: 'AntrepoStok/convertOrdersToExcelReportID/',
  getOrderAgentInformation: 'AntrepoStok/getOrderAgentInformation/',
  GetOrderByOrderId: 'AntrepoStok/getOrderByOrderId/',
  UpdateOrderAgent: 'AntrepoStok/updateOrderAgent',
  evrimTescilTarihi: 'AntrepoStok/evrimTescilTarihi',
  evrimIntacTarihi: 'AntrepoStok/evrimIntacTarihi',
  evrimDetayNoKaydet: 'AntrepoStok/evrimDetayNoKaydet',
  AddGeciciExport: 'AntrepoStok/addGeciciExport',
  ListGeciciExport: 'AntrepoStok/listGeciciExport/',
  CezalarToExcelReport: 'AntrepoStok/evrimCezaToExcelReport',
  ListShippingLine: 'AntrepoStok/listShippingLine',
  ListDahildeIsleme: 'AntrepoStok/dahildeIslemeBelge',
  evrimMalCekildiKontrol: 'AntrepoStok/evrimMalCekildiKontrol',
  AddCommonNumber: 'AntrepoStok/addCommonNumber',
  UpdateOrderMultiple: 'AntrepoStok/updateOrderMultiple',
  AddOrderMultipleDeclaration: 'AntrepoStok/AddOrderMultipleDeclaration',
  BelgelerToExcelReport: 'AntrepoStok/belgelerToExcelReport',
  GetOrderFilter: 'AntrepoStok/getOrderFilter/',
  GetFilteringProducts: 'AntrepoStok/getFilteringProducts',
  AddMultipleOrderNotes: 'AntrepoStok/order/multipleNotes/add',
  AddMultipleOrderDocument: 'AntrepoStok/orders/evrak/yukle',
  AddOrderCreditNote: 'AntrepoStok/order/creditNote/add',
  EvrimCezaByOrder: 'AntrepoStok/evrimCezaByOrder/',
  OrderDemurajControl: 'AntrepoStok/order/demurajControl',
  OrderUrgentChange: 'AntrepoStok/order/urgentChange',
  OrderMasraf: 'AntrepoStok/order/masraf',
  GetOrderRequired: 'AntrepoStok/order/required/',
  AddIhbarTakip: 'AntrepoStok/ihbarTakip/ekle',
  UpdateIhbarTakip: 'AntrepoStok/ihbarTakip/guncelle',
  GetIhbarTakip: 'AntrepoStok/ihbarTakip',
  AddOrderRequired: 'AntrepoStok/order/required/add',
  DeleteOrderRequired: 'AntrepoStok/order/required/delete',
  DeleteOrderDeclaration: 'AntrepoStok/deleteOrderDeclaration',
  AddCompany: 'AntrepoStok/firma/ekle',
  AddProductBasic: 'AntrepoStok/urun/ekle/basic',
  AddAribaExcel: 'AntrepoStok/ariba/dosya/yukle',
  GetDonusDepo: 'AntrepoStok/donusDepo/list',
  GetMasrafList: 'AntrepoStok/masraf/list',
  GetMasrafDetay: 'AntrepoStok/masrafDetay/list/',
  GetMasrafTip: 'AntrepoStok/masrafTip/list/',
  AddMasrafTipSave: 'AntrepoStok/masrafTip/save/',
  GetMasrafEtiket: 'AntrepoStok/masrafEtiket/list/',
  AddMasrafEtiket: 'AntrepoStok/masrafEtiket/add/',
  AddMasrafBeyannameNo: 'AntrepoStok/masrafBeyanname/add/',
  GetMasrafBeyanname: 'AntrepoStok/masrafBeyanname/list/',
  DeleteMasrafBeyanname: 'AntrepoStok/masrafBeyanname/delete',
  DeleteMasrafFatura: 'AntrepoStok/masrafFatura/delete',
  DeleteOrderNotes: 'AntrepoStok/order/notes/delete',
  UpdateOrderTransport: 'AntrepoStok/order/update/transport',
  MasrafFtpUpload: 'AntrepoStok/masraf/ftp/upload',
  MasrafTipProcess: 'AntrepoStok/masraf/tip/process',
  DekontExcelDownload: 'AntrepoStok/masrafDekont/excel/download',
  SigortaCreate: 'AntrepoStok/order/sigorta/create',
  AddBeyannameTaslak: 'AntrepoStok/addBeyannameTaslak/',
  GetBeyannameTaslak: 'AntrepoStok/getBeyannameTaslak/',
  GetEdiCevapList: 'AntrepoStok/ediCevapList/',
  GetBeyannameList: 'AntrepoStok/getBeyannameList/',
  UpdateGGBMaster: 'AntrepoStok/updateGGBMaster/',
  UpdateGGBDetay: 'AntrepoStok/updateGGBDetay/',
  UpdateToplamBrutDagit: 'AntrepoStok/updateToplamBrutDagit/',
  AddDonusDepo: 'AntrepoStok/donusDepo/ekle',
  GetOnaysizDekontList: 'AntrepoStok/dekont/onaysiz',
  UpdateDekontOnay: 'AntrepoStok/dekont/onay',
  DekontFirmaBilgiExcel: 'AntrepoStok/order/dekont/firmaYukle',
  DekontHataBildir: 'AntrepoStok/order/dekont/hataBildir',
  GetOrderCommonNumber: 'AntrepoStok/order/getOrderCommonNumber',
  GetMasrafPODetay: 'AntrepoStok/masrafPODetay/list/',
  GetGroupDetailCode: 'AntrepoStok/grup/kod/',

  // Ürün Tarife Endpoint
  GetFirmaEvrim: 'AntrepoStok/firmaEvrim',
  GetProductTariffList: 'AntrepoStok/urun/liste',
  GetOneProductTariff: 'AntrepoStok/urun/liste/tarife',
  GetProductTariffMoveList: 'AntrepoStok/urun/liste/tarifeHareket',
  PostProductTariff: 'AntrepoStok/urun/liste/tarife',
  PatchProductTariff: 'AntrepoStok/urun/liste/tarife',
  PatchProductTariffConfirmation: 'AntrepoStok/urun/liste/tarife/teyit',
  DeleteProductTariff: 'AntrepoStok/urun/liste/tarife',
  GetProductTariffDraft: 'AntrepoStok/urun/tarife/taslak',
  GetCheckProductTariffDraft: 'AntrepoStok/urun/tarife/taslak/kontrol',
  GetMusavir: 'AntrepoStok/musavir/liste',
};

export { Endpoints };
